import React from 'react'
import { BsLinkedin, BsYoutube } from 'react-icons/bs'
// import { BsInstagram, BsTwitter } from 'react-icons/bs'
// import { FaFacebookF } from 'react-icons/fa'
import { AiFillGithub } from 'react-icons/ai'


const SocialMedia = () => {
    return (
        <div className='app__social'>
            <a href="https://github.com/abhishksen" target="_blank" rel="noreferrer">
                <div>
                    <AiFillGithub />
                </div>
            </a>
            <a href="https://www.linkedin.com/in/senabhishk/" target="_blank" rel="noreferrer">
                <div>
                    <BsLinkedin />
                </div>
            </a>
            <a href="https://www.youtube.com/@senabhishk" target="_blank" rel="noreferrer">
                <div>
                    <BsYoutube />
                </div>
            </a>
            {/* <a href="https://twitter.com/sen_abhishk" target="_blank" rel="noreferrer">
                <div>
                    <BsTwitter />
                </div>
            </a> */}
            {/* <a href="https://www.instagram.com/sen_abhishk/" target="_blank" rel="noreferrer">
                <div>
                    <BsInstagram />
                </div>
            </a> */}
            {/* <a href="">
                <div>
                    <FaFacebookF />
                </div>
            </a> */}
        </div>
    )
}

export default SocialMedia
